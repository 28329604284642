import React, { useEffect } from 'react';
import Navbar from '../../sections/navbar/Navbar';
import gsap from 'gsap';

import './landingpage.css';
import '../../../App.css';

const LandingPage = () => {
  // GSAP Animations
  useEffect(() => {
    gsap.fromTo(
      '.headlines_section h3:first-child',
      { y: '80px', opacity: 0 },
      { y: '0px', opacity: 1, duration: 1.8 }
    );
    gsap.fromTo(
      '.headlines_section h3:last-child',
      { y: '80px', opacity: 0 },
      { y: '0px', opacity: 0.5, delay: 0.5, duration: 1.6 }
    );
    gsap.fromTo(
      '.services_section h2',
      { y: '80px', opacity: 0 },
      { y: '0px', opacity: 1, duration: 1.8 }
    );
  }, []);

  return (
    <div className="landing_page">

      <Navbar />

      <div className="headlines_section">
        <h3>We design your brand...</h3>
        <h3>& brand builds your business!</h3>
      </div>

      <div className="cards_section">

        <div className="card_con">
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="card" key={index}>
              <img src="" alt={`Card ${index + 1}`} />
            </div>
          ))}
        </div>
        
      </div>

      <div className="services_section">
        <h2>
          At usmaniyaz.com, we disrupt the norm in identity and web design. By
          fusing cutting-edge technology with bold design, we create digital
          experiences that stand out from the crowd.
        </h2>
      </div>
    </div>
  );
};

export default LandingPage;
