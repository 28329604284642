import React from 'react'
import "./navbar.css"
import usmaniyaz_logo from "../../../static/images/usmaniyaz_logo.svg"

const Navbar = () => {
  return (
    <>
        <div className="navbar_con">
            <img src={usmaniyaz_logo} alt='usmaniyaz_logo'/>
        </div>
    </>
  )
}

export default Navbar