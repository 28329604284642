import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/landingPage/LandingPage";
import Upcoming from "./components/pages/upcomingPage/Upcoming";


export default function App() {

    return (

        <BrowserRouter>
            <>
                <Routes>
                    <Route path="/" element={<Upcoming />} />
                    <Route path="/landing" element={<LandingPage />} />

                    <Route path="*" element={<LandingPage />} />
                </Routes>
            </>
        </BrowserRouter>
    );
}